import { Observable, ReplaySubject, interval, of } from 'rxjs';
import { first, map } from 'rxjs/operators';
// import * as Sentry from '@sentry/angular-ivy';

import { ActivatedRoute, ActivationStart, Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

import { EnvironmentService, EnvironmentVariables } from '@nexus/core-services/environment.service';
import { TenantService } from '@nexus/auth/tenant.service';
import { JwtUserModel } from './jwt-user.model';

@Injectable()
export class AuthService {
  private apiUrl;
  public loggedIn$ = new ReplaySubject<boolean>();
  public authenticatedUser$ = new ReplaySubject<JwtUserModel>(1);
  public user: any;
  public ready = false;
  public source = interval(30000);
  public terminated = false;
  public jwtHelperService: any;
  public tokenRefresherClock: any;

  constructor(
    private httpClient: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private environmentService: EnvironmentService,
    private tenantService: TenantService,
  ) {
    this.jwtHelperService = new JwtHelperService();
    this.apiUrl = this.environmentService.get(EnvironmentVariables.API_URL);
    this.router.events.subscribe((event) => {
      if (!this.ready && event && event instanceof ActivationStart) {
        this.ready = true;
        const token = event.snapshot.queryParams['token'] || localStorage.getItem('access_token');
        this.loginFlow(token);
      }
    });
    this.source.subscribe((val) => {
      if (this.terminated) {
        return;
      }
      const isTokenExpired = this.jwtHelperService.isTokenExpired(localStorage.getItem('access_token'));
      if (isTokenExpired) {
        this.terminated = true;
        const hubAuthUrl = this.environmentService.get(EnvironmentVariables.HUB_AUTH_URL) + '&s=inactivity';
        window.open(hubAuthUrl, '_self');
      }
    });
    // Create a an interval which will check if the token is expired every 5 minutes
    // If the token is expired, redirect to the login page
    this.tokenRefresherClock = interval(300000).subscribe(async () => {
      const token = localStorage.getItem('access_token');
      if (!token || this.jwtHelperService.isTokenExpired(token)) {
        // Token doesn't exist or is already expired, so skip the rest of the logic
        return;
      }

      let tokenExpirationDate;
      try {
        tokenExpirationDate = this.jwtHelperService.getTokenExpirationDate(token);
      } catch (error) {
        return;
      }

      if (!tokenExpirationDate) {
        // Token expiration date couldn't be retrieved, so skip the rest of the logic
        return;
      }

      // check if delta between now and token expiration date is less than 10 minutes
      const now = new Date();
      const delta = tokenExpirationDate.getTime() - now.getTime();
      const isTokenToExpireSoon = delta <= 600000; // 10 minutes
      if (isTokenToExpireSoon) {
        this.refreshToken().subscribe({
          next: (response: { token: string }) => {
            const { token } = response;
            if (token && token.length > 0) {
              const tokenBearer = token.split(' ')[1];
              if (tokenBearer) {
                localStorage.setItem('access_token', tokenBearer);
              }
            }
          },
        });
      }
    });
  }

  getPolicy(name: string) {
    return this.user.roles.reduce((prev, role: any) => {
      const { policies } = role;
      const targetPolicyIndex = policies.findIndex((targetPolicy) => targetPolicy.name === name);
      if (targetPolicyIndex > -1) {
        prev = policies[targetPolicyIndex];
      }
      return prev;
    }, undefined);
  }

  getAllPolicies() {
    return this.user.roles.reduce((prev, role: any) => {
      const { policies } = role;
      prev = [...prev, ...policies];
      return prev;
    }, []);
  }

  isSubcontractor() {
    return this.user.subcontractors?.length > 0;
  }

  hasRole(name: string): boolean {
    // if(this.user.superUser) return true;
    return !!this.user.roles.find((userRole) => userRole.name.toLowerCase() === name.toLowerCase());
  }

  containsPolicy(name: string, effect: 'Allow' | 'Block' = 'Allow'): boolean {
    const allPolicies = this.user.roles.reduce((prev, role: any) => {
      const { policies } = role;
      prev = [...prev, ...policies];
      return prev;
    }, []);
    return !!allPolicies.find((policy) => policy.action.includes(name) && policy.effect === effect);
  }

  hasRoleMatch(name: string): boolean {
    // if(this.user.superUser) return true;
    return !!this.user.roles.find((userRole) => userRole.name.toLowerCase().includes(name.toLowerCase()));
  }

  hasPolicy(effect: 'Allow' | 'Block', action: string) {
    const allPolicies = this.getAllPolicies();
    return !!allPolicies.find((policy) => policy.effect === effect && policy.action === action);
  }

  hasPolicyMatch(effect: 'Allow' | 'Block', action: string) {
    const allPolicies = this.getAllPolicies();
    return !!allPolicies.find((policy) => policy.effect === effect && policy.action.includes(action));
  }

  readPolicyMatchWithService(effect: 'Allow' | 'Block', service: string, action: string) {
    const allPolicies = this.getAllPolicies();
    return allPolicies
      .filter((policy) => 
        policy.effect === effect && 
        policy.service.includes(service) && 
        policy.action.startsWith(action)
      )
      .map((policy) => policy.action.split(':').slice(1).join(':'));
  }

  getSuperUser() {
    return this.user.superUser;
  }

  loginFlow(token: string): any {
    if (token) {
      this.loginWithApi(token)
        .pipe(first())
        .subscribe({
          error: (err: Error) => this.logout(),
          next: (result: any) => {
            if (result) {
              this.setLoggedIn(token);
            } else {
              this.logout();
            }
          },
        });
    } else {
      this.logout();
    }
  }

  public loginWithApi(token?: string): Observable<any> {
    try {
      const isTokenExpired = this.jwtHelperService.isTokenExpired(token);
      if (isTokenExpired) {
        return of(false);
      }
      const tokenDecoded = this.jwtHelperService.decodeToken(token);
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        }),
      };

      return this.httpClient.post<{ token: string }>(`${this.apiUrl}/v1/auth`, { token }, httpOptions).pipe(
        map((response: any) => {
          this.extractUser(tokenDecoded, response.result);
          return response;
        }),
      );
    } catch (err) {
      return of(false);
    }
  }

  public extractUser(tokenDecoded: any, userFromDb: any): void {
    try {
      const { roles, superUser, hidden, active, _id, groups, subcontractors } = userFromDb;
      const userFromJwt = new JwtUserModel(
        tokenDecoded.email,
        tokenDecoded.exp,
        tokenDecoded.iat,
        tokenDecoded.iss,
        tokenDecoded.jti,
        tokenDecoded.name,
        tokenDecoded.nbf,
        roles,
        groups,
        tokenDecoded.sub,
        tokenDecoded.tenant,
        superUser,
        hidden,
        active,
        _id,
        subcontractors,
      );
      this.user = userFromJwt;
      this.authenticatedUser$.next(userFromJwt);
      // Sentry.setUser({
      //   id: userFromJwt.id,
      //   email: userFromJwt.email,
      //   username: userFromJwt.name,
      //   roles: userFromJwt.roles,
      //   tenant: userFromJwt.tenant,
      // });
    } catch (err) {
      this.authenticatedUser$.next(null);
      // Sentry.setUser(null);
    }
  }

  addToUrl(token: string) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        token,
      },
      queryParamsHandling: 'merge',
      replaceUrl: false,
    });
  }

  clearUrl() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {},
      replaceUrl: true,
    });
  }

  public refreshToken() {
    return this.httpClient.get<any>(`${this.apiUrl}/v1/auth/refresh`, { observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        return response.body.result;
      }),
    );
  }

  public setLoggedIn(token: string) {
    localStorage.setItem('access_token', token);
    this.loggedIn$.next(true);
    this.tenantService.setTenantData();
  }

  public logout() {
    localStorage.removeItem('access_token');
    this.loggedIn$.next(false);
    clearInterval(this.tokenRefresherClock);
    const hubAuthUrl = this.environmentService.get(EnvironmentVariables.HUB_AUTH_URL);
    // Sentry.setUser(null);
    window.open(hubAuthUrl, '_self');
  }

  // Boolean Property
  public get loggedIn(): boolean {
    return localStorage.getItem('access_token') !== null;
  }

  // Simulation
  public isLoggedIn(): Observable<boolean> {
    return this.loggedIn$;
  }
}

// External Functions
export function tokenGetter() {
  return localStorage.getItem('access_token');
}
